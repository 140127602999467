export const composeRedirectUrl = (
  orgSlug: string,
  pathname: string,
): string => {
  if (orgSlug && pathname === '/dashboard') {
    return `/pipelines/${orgSlug}`;
  }

  // This should not happen if not logged in
  return `/home`;
};
