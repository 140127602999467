import { useEffect } from 'react';
import getIsEnterprise from './getIsEnterprise';

import { useRouter } from 'next/router';

import { useOrgSlug } from '~/hooks/usePersistedOrgSlug';
import { composeRedirectUrl } from './composeRedirectUrl';
import { useMeContext } from '~/contexts/MeContext';

export const PipelinesOrProjects = () => {
  const orgSlug = useOrgSlug();
  const me = useMeContext();
  const { query, pathname } = useRouter();
  const isEnterprise = getIsEnterprise();

  useEffect(() => {
    // If the user is not logged-in we want to redirect them properly to vcs-authorize
    // since we're merging web-ui-404 into web-ui
    if (!me?.me && !isEnterprise) {
      window.location.replace('/api/login');
    } else {
      const redirectUrl = composeRedirectUrl(orgSlug as string, pathname);
      window.location.replace(redirectUrl);
    }
  }, [orgSlug, pathname, query, me]);

  return null;
};
